import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/me/aaa/github/personalsite/src/components/post_template.js";
import ImageSource from "src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`grokdb2 - flashcard app in Rust`}</h1>
    <p>{`Source code at `}<a parentName="p" {...{
        "href": "https://github.com/dashed/grokdb2"
      }}>{`github.com/dashed/grokdb2`}</a></p>
    <p>{`This flashcard app (written in Rust) was a `}<em parentName="p">{`second`}</em>{` rewrite of another flashcard app written in Rust: `}<a parentName="p" {...{
        "href": "https://github.com/dashed/grokdb"
      }}>{`github.com/dashed/grokdb`}</a></p>
    <p>{`grokdb was a Rust rewrite of another flashcard app written in golang: `}<a parentName="p" {...{
        "href": "https://github.com/dashed/grokdb-golang"
      }}>{`github.com/dashed/grokdb-golang`}</a></p>
    <br />
    <br />
    <hr />
    <br />
    <ImageSource src="grokdb2-flashcard-app/default.png" alt="default" mdxType="ImageSource" />
    <br />
    <ImageSource src="grokdb2-flashcard-app/deck_list.png" alt="deck_list" mdxType="ImageSource" />
    <br />
    <ImageSource src="grokdb2-flashcard-app/new_card.png" alt="new_card" mdxType="ImageSource" />
    <br />
    <ImageSource src="grokdb2-flashcard-app/deck_stats.png" alt="deck_stats" mdxType="ImageSource" />
    <br />
    <ImageSource src="grokdb2-flashcard-app/new_deck.png" alt="new_deck" mdxType="ImageSource" />
    <br />
    <ImageSource src="grokdb2-flashcard-app/preferences.png" alt="preferences" mdxType="ImageSource" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      